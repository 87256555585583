
import { ApiSettings } from "./api.setting"
import myClient from "./config.api"

const users = {
    getApiZoneList() {
        return myClient.get(ApiSettings.API_GET_ZONE)
    },

    getApiUserList(page: number, pageSize: number, searchText: string) {
        return myClient.get(`${ApiSettings.API_GET_USER_LIST}?page=${page}&page_size=${pageSize}&search_text=${searchText}`)
    },

    getApiSearchUser(page: number, pageSize: number, searchText: string) {
        return myClient.get(`${ApiSettings.API_SEARCH_USER}?page=${page}&page_size=${pageSize}&search_text=${searchText}`)
    },

    putApiCheckBox(params: any) {
        return myClient.put(ApiSettings.API_CHECKBOX, params)
    }
}

export default users
