import { Modal } from "antd";
import { useEffect, useState } from "react"
import { StringType } from "typechain"
import { admin, users } from "../../api"
import { IZoneList } from "../../interfaces/managerSv.interface";

interface IAdminList {
    id: string;
    login: string;
    role: string;
    zone_name: string;
}

const ManagerAdmin = () => {
    const [adminList, setAdminList] = useState<IAdminList[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [zoneList, setZoneList] = useState<IZoneList[]>([]);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [zone, setZone] = useState('');
    const [reloadApi, setReloadApi] = useState(false)


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        handleAddNewAdmin()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        users.getApiZoneList()
            .then(res => {
                const data = res.data.data;
                setZoneList(data);
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        admin.getApiAdminList()
            .then(res => {
                const dataFilter = res.data.data.sort((a: IAdminList, b: IAdminList) => a.id.localeCompare(b.id))
                setAdminList(dataFilter)
            })
    }, [reloadApi])

    const handleAddNewAdmin = () => {
        const params = {
            login: login,
            password: password,
            zone: zone
        }
        admin.postApiAddAdmin(params)
            .then(res => {
                console.log(res);
                setIsModalOpen(false);
                setReloadApi(!reloadApi);
            })
    }

    return <>
        <div className="table-wrapper">
            <button className="btn btn-primary mb-4" onClick={showModal}>Add</button>

            <table className="table table-hover table-user">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Login</th>
                        <th scope="col">Role</th>
                        <th scope="col">Zone</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        adminList.map((item: IAdminList, idx: number) => (
                            <tr key={idx}>
                                <td>{item.id}</td>
                                <td>{item.login}</td>
                                <td>{item.role}</td>
                                <td>{item.zone_name}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        {<Modal title="Add admin" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div className="mb-3">
                <label className="form-label">Login</label>
                <input type="text" className="form-control" onChange={(e) => setLogin(e.target.value)} />
            </div>
            <div className="mb-3">
                <label className="form-label">Password</label>
                <input type="password" className="form-control" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div>
                <label className="form-label">Zone</label>
                <select className="form-select" onChange={(e) => setZone(e.target.value)}>
                    <option value=""></option>
                    {
                        zoneList.map((item, idx) => (
                            <option value={item.id} key={idx}>{item.zone_name}</option>
                        ))
                    }
                </select>
            </div>
        </Modal>}

    </>
}
export default ManagerAdmin