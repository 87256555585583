
import { ApiSettings } from "./api.setting"
import myClient from "./config.api"

const admin = {
    postApiZone(params: any) {
        return myClient.post(ApiSettings.API_POST_AREA, params)
    },
}

export default admin
