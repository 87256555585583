import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from '../page/home'
import Login from '../page/login'

const RouterDom = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/manager/sv" element={<Home />} />
                <Route path="/manager/admin" element={<Home />} />
                <Route path="/manager/kv" element={<Home />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RouterDom