export class ApiSettings {
    public static API_LOGIN                     = "api/v1/admins/login/";

    //user
    public static API_GET_ZONE                  = "api/v1/zone/";
    public static API_GET_USER_LIST             = "api/v1/users/list/";
    public static API_SEARCH_USER               = "api/v1/users/search/";
    public static API_CHECKBOX                  = "api/v1/users/infor/";

    //admin
    public static API_GET_ADMIN_LIST            = "api/v1/admins/";
    public static API_POST_NEW_ADMIN            = "api/v1/admins/regist/";

    //area
    public static API_POST_AREA                 = "api/v1/admins/accept_zone/";

}
