
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authApi } from "../../api";
import { LocalStorage, MESSAGE } from "../../constants/general.constant";
import { ApiSettings } from "../../api/api.setting"

const Login = () => {
    // const navigate = useNavigate();
    const url = process.env.REACT_APP_API

    const handleSubmit = (e: any) => {
        e.preventDefault()
        const { admin, password } = e.target;

        console.log(admin.value, password.value);

        const params = {
            login: admin.value,
            password: password.value
        }

        axios.post(`${url}${ApiSettings.API_LOGIN}`, params)
            .then(res => {
                const msgCode = res.data.meta.code
                if (msgCode === 200) {
                    const data = res?.data?.data
                    console.log(29, data);
                    
                    toast.success(MESSAGE.LOGIN_SUCCESS);
                    const token = data.access_token;
                    const role = data.role;
                    const name = data.login;
                    localStorage.setItem(LocalStorage.TOKEN, token);
                    localStorage.setItem(LocalStorage.ROLE, role);
                    localStorage.setItem(LocalStorage.NAME, name);
                    role === 'admin' ? window.location.href = '/manager/sv' : window.location.href = '/manager/kv';
                } else {
                    toast.error(res.data.meta.message);
                }
            })
            .catch(err => console.log(err))
    }

    return <>
        <div className="login-container">
            <form className="w-100" onSubmit={handleSubmit}>
                <h1 className="text-white mb-4">Login</h1>

                <div className="mb-3">
                    <label htmlFor="admin" className="form-label text-white">Admin</label>
                    <input type="text" id="admin" className="form-control" autoComplete="off" required />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label text-white">Password</label>
                    <input type="password" id="password" className="form-control" autoComplete="off" required />
                </div>
                <button type="submit" className="btn btn-primary mt-2">Submit</button>
            </form>
        </div>
    </>
}

export default Login