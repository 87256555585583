import React from "react";
import "./App.css";
import Login from './page/login'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Manger from './page/managerStudent'
import RouterDom from "./Router";
import { LocalStorage } from "./constants/general.constant";

const App = () => {
  const token = localStorage.getItem(LocalStorage.TOKEN)

  const renderScreen = () => {
    return <>
      <RouterDom />
    </>
  }

  return (
    <div className="site-main">
      
      {!token || window.location.pathname === '/login' && <Login />}
      {renderScreen()}

      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default App;
