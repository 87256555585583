import { useEffect, useState } from "react"
import { users } from "../../api"
import { AutoComplete, Checkbox, Input, Pagination } from 'antd';
import { IUserList, IZoneList } from "../../interfaces/managerSv.interface";

const StudentManager = () => {
    const [zoneList, setZoneList] = useState<IZoneList[]>([]);
    const [userList, setUerList] = useState<IUserList[]>([]);
    const [totalItem, setTotalItem] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [searchValue, setSearchValue] = useState('');
    const [recallApi, setRecallApi] = useState(false);

    useEffect(() => {
        getApiZone()
    }, [])

    useEffect(() => {
        users.getApiUserList(page, pageSize, searchValue)
            .then(res => {
                setTotalItem(res.data.data.count)
                setUerList(res.data.data.results)
            })
            .catch(err => console.log(err))
    }, [page, pageSize, recallApi])

    const getApiZone = () => {
        users.getApiZoneList()
            .then(res => {
                const data = res.data.data;
                setZoneList(data);
            })
            .catch(err => console.log(err))
    }

    const handleSeach = (value: string) => {
        setSearchValue(value);
        setPage(1);
        setPageSize(50);
        users.getApiUserList(1, 50, value)
            .then(res => {
                setTotalItem(res.data.data.count)
                setUerList(res.data.data.results)
            })
            .catch(err => console.log(err))
    }

    const handleChangePagination = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    }

    const renderSearchBox = () => (
        <AutoComplete
            style={{ width: 250 }}
            options={[]}
        >
            <Input.Search size="large" placeholder="Search" onSearch={handleSeach} />
        </AutoComplete>
    )

    const handleCheckBox = (e: any, item: IUserList) => {
        console.log(63, e.target.checked);
        const params = {
            code: item.invited_code,
            zone_id: Number(e.target.value),
            method: e.target.checked ? 'add' : 'remove'
        }
        users.putApiCheckBox(params).then(() => {
            setRecallApi(!recallApi)
        })
    }

    const renderTable = () => (
        <div className="table-wrapper">
            {renderSearchBox()}

            <table className="table table-hover table-user">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Code</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Email</th>
                        {zoneList.map((zone, idx) => <th scope="col" key={idx}>{zone.zone_name}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {
                        userList.map((item: IUserList, index: number) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.invited_code}</td>
                                <td>{item.phone_number}</td>
                                <td>{item.email}</td>
                                {
                                    zoneList.map((el, idx) => (
                                        <td key={idx}>
                                            <div className="form-check">
                                                <input className="form-check-input" onChange={(e) => handleCheckBox(e, item)} type="checkbox"
                                                    value={el.id}
                                                    checked={item.mapping_zone.find(o => o.id === el.id) ? true : false}
                                                />
                                            </div>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )

    return <>
        <div className="">
            {renderTable()}
            <div className="pagination">
                <Pagination size="default" total={totalItem}
                    showSizeChanger showQuickJumper
                    pageSizeOptions={[50, 100]}
                    defaultPageSize={50}
                    current={page}
                    pageSize={pageSize}
                    onChange={handleChangePagination}
                />
            </div>

        </div>
    </>
}
export default StudentManager