import { Input } from "antd"
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { users, zoneApi } from "../../api";
import { IZoneList } from "../../interfaces/managerSv.interface";

const ManagerArea = () => {
    const [zoneList, setZoneList] = useState<IZoneList[]>([]);
    const [zone, setZone] = useState('');
    const [value, setValue] = useState('');


    useEffect(() => {
        users.getApiZoneList()
            .then(res => {
                const data = res.data.data;
                setZoneList(data);
            })
            .catch(err => console.log(err))
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log();

        const params = {
            code: `HSV-2023${e.target.input.value}`,
        }
        zoneApi.postApiZone(params).then(res => {
            toast.success('Submit successfully');
            setValue('')
        })
    }

    const regexInputNumber = (event: any) => {
        return event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    }

    return <>
        <div className="table-wrapper" style={{ height: '100vh' }}>
            <form className="form-kv" onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Code</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text">HSV-2023</span>
                        <input type="text" className="form-control" id="input"
                            onInput={regexInputNumber}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                            minLength={5} required
                        />
                    </div>
                </div>

                <button type="submit" className="btn btn-primary" disabled={value.length < 5}>Submit</button>
            </form>
        </div>
    </>
}

export default ManagerArea