import { Link } from "react-router-dom";
import StudentManager from "../managerStudent";
import ManagerAdmin from "../managerAdmin";
import ManagerArea from "../managerArea";
import { LocalStorage } from "../../constants/general.constant";

const Home = () => {
    const role = localStorage.getItem(LocalStorage.ROLE);
    const name = localStorage.getItem(LocalStorage.NAME);

    const navMenuAdmin = [
        {
            name: 'Quản lí',
            path: '/manager/sv'
        },
        {
            name: 'Tạo',
            path: '/manager/admin'
        }
    ]

    const navMenuSubAdmin = [
        {
            name: 'QLKV',
            path: '/manager/kv'
        }
    ]

    const handleLogout = () => {
        window.location.href = '/login';
        localStorage.clear();
    }

    const renderHeader = () => (
        <>
            <div className="header-container">
                <div></div>
                <div>
                    <p>{name}</p>
                    <p>role: {role}</p>
                </div>
                <button className="btn btn-danger ms-3 me-3" onClick={handleLogout}>Logout</button>
            </div>
        </>
    )

    const renderNavBar = () => (
        <div className="navbar-wrapper">
            <ul className="nav flex-column navbar-container">
                <h2 className="mb-4">Manager</h2>
                {
                    role === 'admin' ? (
                        navMenuAdmin.map((item, idx) => (
                            <Link to={item.path} key={idx}>
                                <li className={`nav-item d-flex align-items-center ${item.path === window.location.pathname ? 'active' : ''}`}>
                                    <i className="bi bi-briefcase-fill"></i>
                                    <span className="ms-2">{item.name}</span>
                                </li>
                            </Link>
                        ))
                    )
                        : (

                            navMenuSubAdmin.map((item, idx) => (
                                <Link to={item.path} key={idx}>
                                    <li className={`nav-item d-flex align-items-center ${item.path === window.location.pathname ? 'active' : ''}`}>
                                        <i className="bi bi-briefcase-fill"></i>
                                        <span className="ms-2">{item.name}</span>
                                    </li>
                                </Link>
                            ))
                        )
                }
            </ul>
        </div>
    )

    const renderMainPage = () => {
        const pathName = window.location.pathname
        if (pathName === '/manager/sv') {
            return <StudentManager />
        }

        if (pathName === '/manager/admin') {
            return <ManagerAdmin />
        }

        if (pathName === '/manager/kv') {
            return <ManagerArea />
        }
    }


    return <>
        <div className="">
            {renderHeader()}
            {renderNavBar()}
            {renderMainPage()}

        </div>
    </>
}

export default Home