
import { ApiSettings } from "./api.setting"
import myClient from "./config.api"

const admin = {
    getApiAdminList() {
        return myClient.get(ApiSettings.API_GET_ADMIN_LIST)
    },

    postApiAddAdmin(params: any) {
        return myClient.post(ApiSettings.API_POST_NEW_ADMIN, params)
    }
}

export default admin
